<template>
  <main class="page__content--wider">
    <div class="page__head2">
      <div
        class="page__head__container align-items-center justify-content-between"
      >
        <div class="page__head__container">
          <div class="page__head__icon">
            <img src="assets/img/page-icon.svg" alt="" srcset="" />
          </div>
          <div class="page__head__text">
            <h1 class="page__head__title">Center</h1>
            <p class="page__head__subtitle">List of all your center created</p>
          </div>
        </div>
        <div class="page__head__actions">
          <div class="d-flex">
            <div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                @click="toggleFilterModal"
              >
                <!--<span class="dropdown__icon--left">
                  <img src="../../../assets/img/filter.svg" alt="" srcset="" />
                </span>-->
                <span class="filter_text"> filter </span>
                <!--<span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>-->
              </button>
              <!-- <b-dropdown
                :html="filter_by_label"
                no-caret
                class="
                  m-2
                  page__body__header__button
                  dropdown--button-grey
                  w-100
                "
                v-model="fetchCenters.sortColumn"
              >
                <b-dropdown-item
                  v-for="item in cats"
                  :key="item.value"
                  :value="item.value"
                  @click="filterByCategory(item.value, item.text)"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown> -->
            </div>
            <div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                export
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item text--capital"
                  href="javascript:void(0)"
                  v-if="userCanExportToPDF"
                  @click="pdfDownload"
                  >PDF</a
                >
                <a
                  class="dropdown-item text--capital"
                  href="javascript:void(0)"
                  v-if="userCanExportToExcel"
                  @click="processDownload"
                  >excel</a
                >
                <!-- <download-excel
                  class="dropdown-item text--capital"
                  style="cursor: pointer"
                  :data="download"
                  :fields="json_fields"
                  name="centers.xls"
                >
                  excel
                </download-excel> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
      <div style="width:80%">
        {{ filterText }}
      </div>
      <div style="display:flex;width:10%">
        <span style="cursor:pointer;font-size:17px" @click="editFilter">
          <i style="padding-right:5px" class="bi bi-pencil-square"></i>
        </span>
        <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
          <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>
    <div class="page__body">
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.8%"
            >
              center Name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14%"
            >
              ID
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.3%"
            >
              Branch
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14%"
            >
              Center status
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.3%"
            >
              Creation date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.3%"
            >
              last modified date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 14.3%"
            >
              Center notes
            </th>
          </tr>
        </thead>
        <tbody v-if="this.loading">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="this.allCentres.items">
          <tr v-for="(task, index) in this.allCentres.items" :key="index">
            <td>
              <div>
                <span
                  class="table__body__text table__text text--capital d-block"
                  style="word-wrap: anywhere"
                  >{{ task.centreName }}</span
                >
                <!-- <span class="table__body__text table__text text--capital d-block text--small">Jerryson ibe</span> -->
              </div>
            </td>
            <td>
              <div>
                <span
                  class="table__body__text table__text text--capital d-block"
                  style="word-wrap: anywhere"
                  >{{ task.centreId }}</span
                >
              </div>
            </td>
            <td>
              <div>
                <span
                  class="table__body__text table__text text--capital d-block"
                  style="word-wrap: anywhere"
                  >{{ task.branchName }}</span
                >
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">{{
                task.centreStatus
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">{{
                task.creationDate | formatDate
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">{{
                task.lastModifiedDate | formatDate
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital" style="word-wrap: anywhere">{{
                task.centreNotes
              }}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <DeleteModal
        :show="!userCanViewCenters"
        :title="'Unauthorized'"
        @close="goToDashboard"
      >
        <template v-slot:modal-body>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Unauthorized
              </h5>
              <!-- <h5 class="text-danger">
                You don
              </h5> -->
              <div class="d-flex mt-5">

                <button
                  data-dismiss="#confirmModal"
                  class="
                    button
                    form__button form__button--lg
                    save-changes
                    fitrem
                  "
                  @click.prevent="goToDashboard"
                >
                  Go to Dashboard
                </button>
              </div>
            </div>
          </div>
        </template>
      </DeleteModal>

      <!-- <Pagination
        :allItems="allCentres"
        :size="size"
      /> -->

      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="fetchCenters.pageSize"
                  class="select select--lg select--page"
                  @change="changePageSize(fetchCenters.pageSize)"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="fetchCenters.pageSize"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              allCentres.pageIndex || allCentres.currentPage 
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              allCentres.totalPages
            }}</span>
            <!-- of
            <span>{{ allCentres.totalCount }}</span> -->
          </div>

          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <button
              class="button button--sm text--regular"
              v-if="allCentres.hasPreviousPage"
              @click="changeCurrentPage('previous')"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              class="button text--regular button--sm ml-2"
              v-for="pages in allCentres.totalPages"
              :key="pages"
              :class="{
                'button--primary text--white': allCentres.pageIndex === pages || allCentres.currentPage === pages,
              }"
              @click="changeCurrentPage(pages)"
            >
              {{ pages }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              v-if="allCentres.hasNextPage"
              @click="changeCurrentPage('next')"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>

      <!-- Alert notification -->
      <div class="toaster show">
        <div
          class="toaster__container"
          v-if="alert.show"
          v-bind:class="{
            'toaster--success': alert.status === 'success',
            'toaster--error': alert.status === 'fail',
            'toaster--warn': alert.status === 'warn',
          }"
        >
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <div>
            <span class="d-block text--small text--600 text--white">{{
              alert.title
            }}</span>
            <span class="d-block text--tiny text--400 text--white">{{
              alert.description
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="getAllCentres"
      @alertError="alertError"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      :filterHeaderName="'Center'"
      filterCategory="center"
    />

  </main>
</template>
<script>
import * as XLSX from "xlsx";
// import JsonExcel from "vue-json-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import ApiService from "@/core/services/general.service";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";

export default {
  name: "all_branches",
  components: {
    // downloadExcel: JsonExcel,
    DeleteModal,
    CreateFilter: () => 
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      )
  },
  data() {
    return {
      showFilterModal: false,
      fetchCenters: {
        pageIndex: 1,
        pageSize: 10,
        filterCategory: "",
        filters: [],
      },
      filter_by_label: "Filter <ion-icon name='caret-down-outline'/>",
      cats: [
        {
          value: "centrename",
          text: "Center Name",
        },
        {
          value: "centrestatus",
          text: "Status",
        },
        {
          value: "date",
          text: "Creation Date",
        },
      ],
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      allCentres: {},
      loading: true,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      json_fields: {
        "Center Name": "name",
        "ID": "id",
        "Branch": "branch",
        "Center Status": "status",
        "Creation Date": "date",
        "Last Modified Date": "date_mod",
        "Center notes ": "note",
      },
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
      paging: "",
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: '/dashboard' });
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    getAllCentres() {
      this.deleteFilter()
      this.loading = true;
      this.fetchCenters.filters = [];
      this.fetchCenters.filterCategory = "";
      ApiService.post(
        `Centre/Centres`, this.fetchCenters
      )
        .then((response) => {
          this.allCentres = response.data.data;
          this.exportData = response.data.data.items;
          this.paging = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.alertError(error.response.data.data.message);
        });

      if (!this.userCanViewCenters) return this.alertWarning('You don not have permission to view this page');
    },
    getAllFilteredCentres() {
      this.loading = true;
      ApiService.post(
        `GeneralFilter/ApplyFilter`, this.fetchCenters
      )
        .then((response) => {
          this.allCentres = { ...response.data, items: response.data.data };
          this.paging = response.data;
          this.loading = false;
          this.filterText = response.data.filterText.replace(/_/g, " ")
          this.filterEdit = response.data.filters;
          this.getExportData()
          this.alertSuccess("Successfully filtered centers");
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getExportData() {
      if(this.paging.totalCount > 0) {
        ApiService.post(`GeneralFilter/ApplyFilter`,this.fetchCenters)
          .then((res) => {
            this.exportData = res.data.data;
          })
          .catch((e) => {
            const error = e.response.data.message;
            this.alertError(error);
          });
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = []
    },
    editFilter() {
      this.showEditedValue = Math.random()
      this.toggleFilterModal();
    },
    sendFilter(value) {
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchCenters.filters = filterArray;
      this.fetchCenters.pageIndex = 1;
      this.fetchCenters.pageSize = 10;
      this.fetchCenters.filterCategory = "center";
      this.getAllFilteredCentres();
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Centers List Download",
        Subject: "Centers List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Centers");

      let sheetData = [[
        "Center Name",
        "ID",
        "Branch",
        "Center Status",
        "Creation Date",
        "Last Modified Date",
        "Center notes",
      ]];
      if (this.exportData) {
        this.exportData.map((item) => {
          let new_arr = [];
          new_arr.push(item.centreName);
          new_arr.push(item.centreId);
          new_arr.push(item.branchName);
          new_arr.push(item.centreStatus);
          new_arr.push(moment(item.creationDate).format("DD-MMM-YYYY"));
          new_arr.push(moment(item.lastModifiedDate).format("DD-MMM-YYYY"));
          new_arr.push(item.centreNotes);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Centers"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "centers.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Center Name", dataKey: "name" },
        { title: "ID", dataKey: "id" },
        { title: "Branch", dataKey: "branch" },
        { title: "Center Status", dataKey: "status" },
        { title: "Creation Date", dataKey: "date" },
        { title: "Last Modified Date", dataKey: "date_mod" },
        { title: "Center notes ", dataKey: "note" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("centers.pdf");
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    changePageSize(pageNum) {
      this.fetchCenters.pageIndex = 1;
      this.fetchCenters.pageSize = pageNum;
      if(this.fetchCenters.filterCategory === "center") {
        this.getAllFilteredCentres();
      } else {
        this.getAllCentres();
      }
    },
    changeCurrentPage(pages = null) {
      if (pages === "next") {
        this.fetchCenters.pageIndex++;
        if(this.fetchCenters.filterCategory === "center") {
          this.getAllFilteredCentres();
        } else {
          this.getAllCentres();
        }
      } else if (pages === "previous") {
        this.fetchCenters.pageIndex--;
        if(this.fetchCenters.filterCategory === "center") {
          this.getAllFilteredCentres();
        } else {
          this.getAllCentres();
        }
      } else if (pages !== null) {
        this.fetchCenters.pageIndex = pages;
        if(this.fetchCenters.filterCategory === "center") {
          this.getAllFilteredCentres();
        } else {
          this.getAllCentres();
        }
      }
    },
    // filterByCategory(id, name) {
    //   this.filter_by_label = name + " <ion-icon name='caret-down-outline'/>";
    //   this.fetchCenters.sortColumn = id;
    //   this.getAllFilteredCentres();
    // },
  },
  computed: {
    ...mapState({ 
      user: (state) => state.auth.user,
    }),
    download() {
      let data = [];
      if (this.exportData) {
        this.exportData.forEach((item) => {
          let new_obj = {};
          new_obj.name = item.centreName;
          new_obj.id = item.centreId;
          new_obj.branch = item.branchName;
          new_obj.status = item.centreStatus;
          new_obj.date = moment(item.creationDate).format("DD-MMM-YYYY");
          new_obj.date_mod = moment(item.lastModifiedDate).format(
            "DD-MMM-YYYY"
          );
          new_obj.note = item.centreNotes;
          new_obj.created_by = item.createdBy;

          data.push(new_obj);
        });
      }

      return data;
    },
    userCanViewCenters() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Centre);
      const { permissionsList } = user;
      return permissionsList.CanViewCentre;
    },
    userCanViewReport() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Reports);
      const { permissionsList } = user;
      return permissionsList.CanViewReports;
    },
    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
  },
  mounted() {
    this.getAllCentres();
  },
};
</script>
<style scoped>
.has-checkbox .checkmark {
  top: -10px;
}
</style>
